import Axios from 'axios'
import { getEnvVariable } from '../enviornment';
import { UserActions } from '../Redux/Actions/UserAction';
import { Http } from './Http';
export class Api{
    static login(data:object){
        return Http.post(getEnvVariable().laravel_base_api+'user/signin',data,{baseURL:null});
    }
    static add(data:object){
        return Http.post('/users',data);
    }
    static get(pgnum:number){
        console.log(pgnum, " pgnum API")
        // return Http.get(`${getEnvVariable().laravel_base_api}user/getUsers?search=&start=${pgnum ? pgnum : 10 }&length=10`);
        return Http.get(`${getEnvVariable().laravel_base_api}user/getUsers?search=&start=${pgnum ? pgnum : 0 }&length=`);
    }
    static getuser(id:number){
        return Http.get(getEnvVariable().laravel_base_api+'user/getUser?user_id='+id);
    }

    static deleteUser(id:number){
        return Http.delete(getEnvVariable().laravel_base_api+'user/deleteuser?user_id='+id);
    }
  
    static updateUser(user:any){
        console.log(user,'apidata');
        return Http.post(getEnvVariable().laravel_base_api+'user/updateProfile',user);
    }    

    static getDevice(id:any){
        return Http.get(getEnvVariable().laravel_base_api+'user/getSync?user_id='+id);
    }  
    
    
    static getDevices(id:any){        
        return Http.get(getEnvVariable().laravel_base_api+'user/getDevices?user_id='+id);       
    }

    static updateTime(data:object){    
        return Http.post(getEnvVariable().laravel_base_api+'user/updateTime',data);
    }

    static getTime(){
        return Http.get(getEnvVariable().laravel_base_api+'user/getTime');
    }
    static deleteLogs(data:object){
        return Http.post(getEnvVariable().laravel_base_api+'user/deleteLogs',data);
    }
    


}