import * as React from 'react' 
import { connect } from 'react-redux';
import { Link,NavLink,Outlet } from "react-router-dom";
import { boolean } from 'yup';
import Layout from '../components/Layout';
import UserList from '../components/UserList';
import { rootReducerState } from '../Redux/Reducers';
import { UserRepositry } from '../services/UserRepository';
import ReactPaginate from 'react-paginate';
import { consoleTestResultHandler } from 'tslint/lib/test';
import { withRouter } from './WithRouter';
import DeviceList from '../components/DeviceList';
import Popup from '../components/Popup';
import { toast,ToastContainer, ToastOptions, ToastPosition  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface Props {
    deviceLog:any,
    loading:boolean,
    deviceLogItems:any,
    deviceListItems:any,
    navigate:any,
    match:any,
    id:number,
    deviceList:any,   
    deleteDevice: any
}

interface State{
    totalPageCount:number,
    perPage:number,
    currentPage:number,
    search:string,    
    items:any,  
    deleteItem:number,
    deleteStatus:boolean,
    deleteContinueBtn:boolean,
    deleteData:any
}

class Devices extends React.Component<Props,State>{
    public total = 0;
    constructor(props:Props){
        super(props);      
        this.state ={
            totalPageCount : 2,
            perPage : 1,
            currentPage:0,
            search:'',
            items:[],         
            deleteItem:0,
            deleteStatus:false,
            deleteContinueBtn:false,
            deleteData:{}
        }       
    }

    deleteToast = () => {
        const toastOptions: ToastOptions = {
            position:  'top-right' as ToastPosition,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        };
        toast.success("Device Deleted Successfully!", toastOptions);
    }

    handleDeleteDevice(value:any):any {
        this.confirmToast();
            this.setState({deleteData:value})
        

     }

     deleteContinue = (value:any) => {
        const data = {
            user_id:value?.user_id,
            connected_deviceid:value.connected_deviceid,
        };

        this.props.deleteDevice(data);
        const bodyElement = document.querySelector('body');
if (bodyElement) {
  bodyElement.classList.add("disable-click");
} 

            this.setState(prev=>({
                deleteStatus:true
            }),() => {     
                setTimeout(() => {
                    this.deleteToast();                  
                    this.handleFetch(this.state.currentPage);
                    this.setState({deleteStatus:false})
                    if (bodyElement) {
                        bodyElement.classList.remove("disable-click");                       
                      }  }, 1000)
               
            })
     }

     confirmToast = () => {    
        const toastOptions: ToastOptions = {
            position:  'top-center' as ToastPosition,
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,                                
            draggable: false,
            progress: undefined,
            closeButton: false,
        };
        toast.success(<div>
            <p>Are you sure you want to delete this device?</p>
            <button onClick={this.confirmAction} className='btn btn-primary'>Yes</button>
            <button onClick={this.cancelAction} className='btn btn-danger ml-15'>No</button>
          </div>, toastOptions);
    }

    confirmAction = () => {       
        this.setState({deleteContinueBtn:true})  
        this.deleteContinue(this.state.deleteData)
        toast.dismiss();
      };

      cancelAction = () => {       
        toast.dismiss();
      };

    componentDidMount(): void {
        this.handleFetch(this.state.currentPage);            
    }

    handleFetch = (currentPage:number) => {
        console.log(currentPage,'currentPage');
        // const items = this.props.deviceLogItems(currentPage);        
        const items2 = this.props.deviceListItems(currentPage);        
        
    }

    handlePageClick = (selectedObject:any)=>{
        console.log(selectedObject.selected);
        this.setState({currentPage:selectedObject.selected});
        this.handleFetch(selectedObject.selected);
    }   

    setData = (val:any)=>{
       console.log(val)
        this.setState({currentPage:val});
        this.props.navigate('/users?search='+this.state.search+'&page='+val);
        //this.querySet();        
    }
    
    querySet = () =>{
        console.log(this.state.currentPage);
        this.props.navigate('/users?page='+this.state.currentPage);
    }


    
    render(){
        if(this.props.deviceLog.data?.length > 0){
            this.total = Math.ceil(2/this.state.perPage);
        }        
        return(
            
            <div className="content-wrapper">

<div className="toastInner">        
                <ToastContainer />
            </div>
    
                <section className="content-header">
                    <h1>
                       Device
                    </h1>
                    
                </section>

                
                <section className="content content-height min-height-600">
                
                <div className="row">
                    <div className="col-xs-12">
                    <div className="box">
                        <div className="box-header">
                        <h3 className="box-title">Device List</h3>                       
                        </div>                        
                        <div className="box-body table-responsive no-padding">
                        <table className="table table-hover">
                            <thead>
                            <tr>                            
                            <th>Device</th>
                            <th>Device Name</th>
                            <th>Delete</th>
                            <th></th>
                            
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.loading ? (<div className="overlay"><i className="fa fa-refresh fa-spin"></i></div>) : null}
                            {this.props.deviceList.data?.length > 0 ?
                              this.props.deviceList.data.map(function(this:Devices,data:any,index:any){                               
                               // return (<DeviceList key={index} devicelog={data} uniqueDevice={true}/>)
                                return ( <tr>
                                    <td>{data.connected_deviceid}</td>
                                    <td>{data?.device_name == null ? ('No Name'): (data?.device_name)}</td>

                                                
                                    <td><button onClick={()=>{this.handleDeleteDevice(data)}} className={'btn btn-primary'}>Delete</button></td>

                                    <td></td>
                                </tr>)
                            },this): null}
                            </tbody>
                        </table>
                        </div>                       
                        {/* {this.total > 0 ? (
                            <div className="box-footer clearfix">
                                <ul className="pagination pagination-sm no-margin pull-right">
                                    <li><Link to="#">&laquo;</Link></li>
                                    
                                    <li><Link to='javascript:void(0);' onClick={()=>this.setData(1)}>1</Link></li>
                                    <li><Link to='javascript:void(0);' onClick={()=>this.setData(2)}>2</Link></li>
                                    <li><Link to='javascript:void(0);' onClick={()=>this.setData(3)}>3</Link></li>

                                    <li><a href="#">&raquo;</a></li>
                                </ul>
                            </div>
                           ) :null} */}
                    </div>                    
                    </div>
                </div>
                </section>            
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch:any)=>({
    deviceLogItems: ()=>dispatch(UserRepositry.getDeviceLog(window.location.pathname.split('/')[2])),
    deviceListItems: ()=>dispatch(UserRepositry.getDeviceList(window.location.pathname.split('/')[2])),
     deleteDevice: (data: any)=>dispatch(UserRepositry.deleteDevice(data)),
})
const mapStateToProps = (state:rootReducerState)=>({
    deviceLog: state.userReducer.deviceLog,
    deviceList: state.userReducer.deviceList,
    loading: state.userReducer.loading,    
})
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Devices));