import * as Yup from 'yup';
export class Validators{
    public static registration(){
        return Yup.object({
            name:Yup.string().max(15,'Must be 15 character or less').required('required'),
            email:Yup.string().email('Invalid email address').required('required'),
            password:Yup.string().max(6,'Password must be 6 character').required('required'),
        })
    }
    public static login(){
        return Yup.object({
            email:Yup.string().email('Invalid email address').required('Please enter email address'),
            password:Yup.string()
            .max(30,'Max 30 character allowed')
            .min(8,'Minimum 8 character required')
            .required('Please enter password'),
        })
    }

    public static update(){
        return Yup.object({
            username:Yup.string().required('Please Enter Username'),
            // email:Yup.string().email('invalid Email Address').required('Please enter email address'),
            // deviceId:Yup.string().required('Please enter DeviceId'),
        })
    }

}