import * as React from 'react'
import { Link,NavLink,Outlet } from "react-router-dom";
import moment from 'moment';
interface State {

}

interface Props{
    devicelog:any
    uniqueDevice:any
}

const DeviceList = (props:Props)=> {
    return(
        <tr>          
            <td>{props.devicelog.connected_deviceid}</td>
            <td> {props?.uniqueDevice ? (''): (props.devicelog.device_name == "null"|| props.devicelog.device_name == null  ? ('No Name'): (props.devicelog.device_name))}</td>
            {/* <td> {props?.uniqueDevice ? (''): (moment(props.devicelog.sync_time).format("YYYY-MM-DD hh:mm:ss"))} </td>             */}
            <td> {props?.uniqueDevice ? (''): (props.devicelog.sync_time)} </td>            
            <td> {props?.uniqueDevice ? (''): (
            props.devicelog.lat !== '' && props.devicelog.longitude !== '' ? (<a href={`https://www.google.com/maps/search/?api=1&query=${props.devicelog.lat},${props.devicelog.longitude}`} target="_blank">{props.devicelog.lat},{props.devicelog.longitude}</a>) : ('No Location Found')
            
            )}</td>

            <td>{props?.uniqueDevice ? (''): (props.devicelog?.rssi == "null" || props.devicelog?.rssi == null? ('Lost Signal') :(props.devicelog?.rssi))}</td>

            <td>{props?.uniqueDevice ? (''): ((props.devicelog.success != 'undefined' && props.devicelog.success != null) ?  props.devicelog.success : '')}  </td>
        </tr>
    )
}

export default DeviceList;